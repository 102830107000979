.slider {
	margin: -20px auto 10px auto;
	width: 100%;
	max-width:1200px;
	overflow: hidden;
	position:relative;
	left:0;
	right:0;
	z-index: 99;
	background:white;
	height: 500px;
}

.slider.edit-mode {
	height: auto;
}

.slider ul {
	display: block;
	list-style-type: none;
	margin: 0 0;
	padding: 0;
	width: 100%;
	height: auto;
	overflow: hidden;

}

.slider ul:before,
.slider ul:after {
	content: " ";
	display: table;
}

.slider ul:after {
	clear: both;
}

.slider ul:before,
.slider ul:after {
	content: " ";
	display: table;
}

.slider ul:after {
	clear: both;
}

.slider li {
	display: block;
	position: relative;
	padding: 0;
	margin: 0 0 0 0;
	width: 100%;
	height: auto;
	background:white;
	top: 0;
	left: 0;
	min-height: 100%;
}

.slider:not(.edit-mode) li.initially-hidden{
	display: none;
}

.slider li .slider-link {
	display: block;
	margin: 0 0;
	padding: 0;
	width: 100%;
	height: 500px;
	text-decoration: none;
	position:relative;
	z-index: 2;

}

.slider li .slider-link:before,
.slider li .slider-link:after {
	content: " ";
	display: table;
}

.slider li .slider-link:after {
	clear: both;
}

.slider li .slider-link:before,
.slider li .slider-link:after {
	content: " ";
	display: table;
}

.slider li .slider-link:after {
	clear: both;
}

.slider li .slider-link:focus,
.slider li .slider-link:active,
.slider li .slider-link:hover {
	text-decoration: none;

}
.slider li .slider-link:focus{
outline: 1px dotted white;
	outline-offset: -6px;
}
.slider {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.slider  img.slider-img {
	position: absolute;
	width:1200px;
	height:auto;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index:1;

    &--left {
        left: 0;
        transform: translateX(0%);

        @media @screen-md-max {
            transform: translateX(-10%)
        }
    }

    &--right {
        right: 0;
        left: auto;
        transform: translateX(0%);

        @media @screen-md-max {
            transform: translateX(10%)
        }
    }
}
.slider div.left,
.slider div.right {
	position: relative;
	float: left;
	width: 50%;
	height: 100%;
}

.content .slider  .slider-content-title {
	/*margin-top:60px;*/
	font-family: "Frutiger Next Regular", "Source Sans Pro Regular", Arial;
	color: #222222;
	font-size: 28px;
	font-weight: normal;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.slider div p {
	font-family: "Frutiger Next Regular", "Source Sans Pro Regular", Arial;
	color: #222222;
	font-weight: normal;
}

.slider div p.btn {
	color: white;
}

.slider a:hover div p.btn {
	color: white;
}

.slider a:hover div p:hover.btn {
	color: #d40027;
}

.slider__controls {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	bottom: 40px;
	right: 0;
	z-index: 4;
	width: 100%;
	max-width: 570px;
}

@media @only-small-devices {
	.slider__controls {
		bottom: 10px;
	}

	.slider {
		height: 200px!important;
	}

	.slider img.slider-img {
		height: 200px;
		object-fit: contain;
	}
}


.slider-action-link {
	display: block;

	color: #fff;
	opacity: 0.7;

	background: #fff;
	padding: 4px 10px 0 10px;
	box-shadow: none;
	border: 1px solid #666;
	margin: 0 10px 0 0;
}

.slider .slider-action-link img {
	display: inline-block;
	margin: 0;
	width: 20px;
	height: 20px;
	opacity: 0.6;
}
.slider .slider-action-link:focus {
	opacity: 1.0;
	outline: 1px dotted white;
}
.slider .slider-action-link:hover {
	opacity: 1.0;
}


.slider-next-link {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 100px;
	height: 100%;
	color: transparent;
	z-index: 3;
	text-align: right;
	-webkit-transition: color 0.5s;
	-moz-transition: color 0.5s;
	transition: color 0.5s;
}

.slider-next-link span {
	position: absolute;
	top: 50%;
	right: 10px;
	font-size: 90px;
	margin-top: -15px;
}

.slider-next-link:hover {
	color: #6a6a6a;
}


.slider-prev-link {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 100%;
	color: transparent;
	z-index: 3;
	text-align: right;
	-webkit-transition: color 0.5s;
	-moz-transition: color 0.5s;
	transition: color 0.5s;
}

.slider-prev-link span {
	position: absolute;
	top: 50%;
	left: 10px;
	font-size: 90px;
	margin-top: -15px;
}

.slider-prev-link:hover {
	color: #6a6a6a;
}
.slider-content-link-panel{
	width:90px;
	//position:absolute;
	//right:10px;
	//bottom:220px;
	z-index: 99;
	opacity: 1;
	transition: opacity .15s ease;
}

.slider-content-link {
	display:inline-block;
	width: 40px;
	height:40px;
	background: white;
	opacity:0.7;
	text-align:center;
	font-size:26px;
	border: 1px solid #666;
	padding-top: 6px;
	color:black;
	opacity:0.7;

	span {
		opacity: .7;
	}
}
.slider-content-link:hover{
	 opacity:1.0;
	 }
.slider-content-link:focus{
	opacity:1.0;
	outline: 1px dotted @oebb-red;
}


.slider-action-link:hover {
	color: #222222;
}

.slider li.transition {
	-webkit-transition: left 0.9s;
	-moz-transition: left 0.9s;
	transition: left 0.9s;
}

.slider-point-panel {
    display: block;
    z-index: 4;
    text-align: right;
    max-width: 550px;
    background: 0 0;
    padding: 0 20px;
	margin-right: auto;

	@media @only-small-devices {
		margin-right: 0;
	}
}


.slider-point {
	display: inline-block;
	border-radius: 50%;
	background: #fff;
	width: 14px;
	height: 14px;
	margin: 0 3px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
	border: 1px solid @dark-red;
	padding: 0;
}

.slider-point.active {
	background: @dark-red;
}
.slider-point:focus,.slider-point:hover{
	background: #ccc;
	outline: 1px dotted white;
}


.slider-content {
	display: block;
	position: absolute;
	bottom: 92px;
	right: 0;
	background:transparent;
    width:570px;
	height:195px;
	z-index: 1;
}
@media @only-small-devices{
	.slider-content {
		width:90%;
		height:105px;
		top: 50px;
	}
}


.slider-content-decorator {
	display: block;
	position: absolute;
	top:0;
	left:0;
	background:black;
	opacity: 0.7;
	width:570px;
	height:195px;
}

@media @only-small-devices{
	.slider-content-decorator {
		height: 145px;
	}
}
.slider-content-text {
	display: block;
	position: absolute;
	bottom:0;
	right:0;
	background: rgba(0,0,0,.7);
	width:585px;
	min-height:195px;
	padding:30px;
}

@media @only-small-devices{
	.slider-content-text {
		width:80%;
		min-height: auto;
		padding: 20px;
	}
	.slider-content-text img{
		display:none;
	}
	.slider-content-link-panel{
		display:none;
	}

}


.slider .slider-content .slider-content-title {
	font-family: "Frutiger Next Light", "Source Sans Pro Light", Arial;
	color: white;
	font-size: 36px;
	font-weight: bold;
	margin-top: 56px;
	margin-bottom: 0;

}
@media @only-small-devices{
	.slider .slider-content .slider-content-title{
	margin-top: 26px;
	font-size: 20px;
	}
}
@media @only-mini-devices{
	.slider .slider-content .slider-content-title{
        font-size: 16px;
	}
}

.slider-content h4 {
	color: white;
	font-size: 18px;
	text-shadow: 1px 1px 1px black;
	font-weight: normal;
}

.slider-content span.btn {
	display: inline-block;
	border: 1px solid white;
	padding: 2px 16px;
	color: white;
	font-size: 16px;
	text-shadow: 1px 1px 1px black;
	font-weight: normal;
}

.slider-content span.btn:hover {
	background: #d40027;
}

.slider-progress-bar{
	font-family: "Frutiger Next Regular", "Source Sans Pro Regular", Arial;
	position:absolute;
	text-transform: uppercase;
	color:black;
	background:white;
	font-weight:bold;

}
.slider-progress-bar span{
	 display: inline-block;
	font-weight:bold;
	padding:8px 16px;
	font-size:20px;
}

.slider-progress-state {
	position: absolute;
	background:@oebb-red;
	text-transform: uppercase;
	color:white;

	top: 0;
	left: 0;
	overflow:hidden;
	white-space: nowrap;
	width:0;

}
.slider-progress-transition .slider-progress-state{
	width:0;
	-webkit-transition: width 4.0s linear;
	-moz-transition: width 4.0s linear;
	transition: width 4.0s linear;
}
.slider li.slider-progress-transition.active .slider-progress-state {
	width:100%;
}

@media @only-small-devices{
	.slider-progress-bar{
		width:100%;
	}
	.slider-progress-bar, .slider-progress-state{
		height:4px;
		overflow:hidden;
	}
}

.slider--magazine {
	max-width: none;
	margin-top: 0;
	height: auto;
	min-height:  270px;

	.slider-title {
		color: @white;
		font-size: 50px;
		font-family: "Frutiger Next Bold";
		font-weight: lighter;
		line-height: 50px;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
		position: absolute;
		top: 60%;
		transform: translate(-50%);
		left: 50%;
		width: 100%;

		@media @only-small-devices {
			font-size: 33px;
			line-height: 33px;
		}

		@media (max-height:500px) {
			top: 10%;
		}
	}

	.slider-content {
		right: 50%;
		transform: translateX(50%);
		z-index: 1;
		text-align: center;
		width: 100%;
		height: 100%;
		bottom: 0;
		// display: flex;
		// justify-content: flex-end;
		// flex-direction: column;
		// align-items: center;
		padding-bottom: 100px;


		@media (max-height:768px) {
			bottom: 20px;
			height: ~'calc(100% - 20px)';
			padding: 0 20px 20px;
		}

		@media @only-small-devices {
			padding: 0 20px 20px;
			bottom: 20px;
			height: ~'calc(100% - 20px)';
		}
	}

	.slider__controls {
		left: 0;
		right: auto;
		padding: 0 10px;
		bottom: 10px;
		max-width: none;
	}

	.slider-action-link {
		position: absolute;
		left: 10px;
		right: auto;
		bottom: 70px;

		@media (max-height:768px) {
			bottom: 10px;
		}

		@media @only-small-devices {
			bottom: 10px;
		}
	}

	.slider-content-link-panel {
		position: absolute;
		bottom: 10px;
		left: 10px;
		right: auto;

		@media (max-height:768px) {
			left: 70px;

			.slider-content-link {
				width: 30px;
				height: 30px;
				font-size: 20px;
				line-height: 20px;
			}

		}
	}

	.slider-point-panel {
		margin: 0 auto;
	}

	.slider-img-wrapper {
		background-position: 50%;
		background-size: cover;
		width: 100%;
		height: 100%;
	}

	img.slider-img {
		display: none;
	}

	.slider-logo {
		width: 80%;
		max-width: 315px;
		position: absolute;
		bottom: 60px;
		left: 50%;
		transform: translate(-50%,0);

		@media (max-height:768px) {
			max-width: 120px;
			bottom: 10px;
		}
	}

	&.edit-mode {
		img.slider-img {
			display: block;
			position: static;
			margin: 0 auto;
		}
	}
}
