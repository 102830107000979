#bahnhof-search-box {
	margin: 40px 0;
}

.bahnhof-search-panel {
	margin: 0;
	padding-left: 0;
	position: relative;
}

#bahnhof-search-icon {
	position: absolute;
	left: 8px;
	top: 10px;
	font-size: 20px;
}

.bahnhof-search-panel input[type=text] {
	margin-left: 0;
	padding-left: 35px;
	width: ~'calc(100% - 45px)';
	background: transparent;
	height: 38px;
}

form button[type=submit].bahnhof-search__submit,
form input[type=submit].bahnhof-search__submit {
	top: 0;
	height: 38px;
}

#fess-bahnhof-search-results {
	max-width: 75%;

	@media @medium-devices-and-smaller{
		max-width: none;
	}

	ul.search-result-list {
		max-width: none;
	}
}

.bahnhof {
	.content table th,
	.container table th {
		vertical-align: top;
	}

	.ticket-counter {
		display: inline;

		&--opening-hours {
			display: grid;
			grid-template-columns: max-content auto;
			align-items: center;
			gap: 1em;
			margin-bottom: 8px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.individual-search-text {
		width: 75%;

		@media @medium-devices-and-smaller{
			width: 100%;
		}
	}
}
