
.ic-arrow1-down:before {
	content: "\e601";
}

.ic-arrow1-left:before {
	content: "\e602";
}

.ic-arrow1-right:before {
	content: "\e603";
}

.ic-arrow1-up:before {
	content: "\e604";
}

.ic-arrow2-down:before {
	content: "\e609";
}

.ic-arrow2-left:before {
	content: "\e60a";
}

.ic-arrow2-right:before {
	content: "\e60b";
}

.ic-arrow2-up:before {
	content: "\e60c";
}

.ic-arrow3-down:before {
	content: "\e60d";
}

.ic-arrow3-left:before {
	content: "\e60e";
}

.ic-arrow3-right:before {
	content: "\e60f";
}

.ic-arrow3-up:before {
	content: "\e610";
}

.ic-arrow4-down:before {
	content: "\e611";
}

.ic-arrow4-left:before {
	content: "\e614";
}

.ic-arrow4-right:before {
	content: "\e616";
}

.ic-arrow4-up:before {
	content: "\e617";
}

.ic-arrow5-down:before {
	content: "\e690";
}

.ic-arrow5-left:before {
	content: "\e691";
}

.ic-arrow5-right:before {
	content: "\e692";
}

.ic-arrow5-up:before {
	content: "\e693";
}

.ic-attachment:before {
	content: "\e61a";
}

.ic-audio1:before {
	content: "\e698";
}

.ic-audio2:before {
	content: "\e697";
}

.ic-audio3:before {
	content: "\e696";
}

.ic-building:before {
	content: "\e900";
}

.ic-calculator:before {
	content: "\e624";
}

.ic-calendar:before {
	content: "\e625";
}

.ic-camera:before {
	content: "\e627";
}

.ic-check1:before {
	content: "\e629";
}

.ic-check2:before {
	content: "\e62a";
}

.ic-check3:before {
	content: "\e62b";
}

.ic-clock:before {
	content: "\e901";
}

.ic-close1:before {
	content: "\e62c";
}

.ic-close2:before {
	content: "\e67b";
}

.ic-comments:before {
	content: "\e631";
}

.ic-contrast:before {
	content: "\e632";
}

.ic-delete:before {
	content: "\e633";
}

.ic-download:before {
	content: "\e902";
}

.ic-edit:before {
	content: "\e669";
}

.ic-edit2:before {
	content: "\e903";
}

.ic-error:before {
	content: "\e635";
}

.ic-flag:before {
	content: "\e638";
}

.ic-gps:before {
	content: "\e648";
}

.ic-help:before {
	content: "\e679";
}

.ic-home:before {
	content: "\e904";
}

.ic-info:before {
	content: "\e643";
}

.ic-laptop:before {
	content: "\e667";
}

.ic-link:before {
	content: "\e634";
}

.ic-loading:before {
	content: "\e905";
}

.ic-locator:before {
	content: "\e647";
}

.ic-lock:before {
	content: "\e649";
}
.ic-mail1{
	line-height: 1.20em;
}
.ic-mail1:before {
	content: "\e64f";

}

.ic-mail2:before {
	content: "\e650";
}

.ic-mail22:before {
	content: "\e652";

}

.ic-menue:before {
	content: "\e600";
}

.ic-mime-audio:before {
	content: "\e906";
}

.ic-mime-doc:before {
	content: "\e907";
}

.ic-mime-folder:before {
	content: "\e908";
}

.ic-mime-image:before {
	content: "\e909";
}

.ic-mime-link:before {
	content: "\e90a";
}

.ic-mime-movie:before {
	content: "\e90b";
}

.ic-mime-noname:before {
	content: "\e90c";
}

.ic-mime-pdf:before {
	content: "\e90d";
}

.ic-mime-ppt:before {
	content: "\e90e";
}

.ic-mime-txt:before {
	content: "\e90f";
}

.ic-mime-xls:before {
	content: "\e910";
}

.ic-mime-zip:before {
	content: "\e911";
}

.ic-minus1:before {
	content: "\e655";
}

.ic-minus2:before {
	content: "\e656";
}

.ic-minus3:before {
	content: "\e657";
}

.ic-minus4:before {
	content: "\e658";
}

.ic-more:before {
	content: "\e661";
}

.ic-move:before {
	content: "\e662";
}

.ic-movie:before {
	content: "\e912";
}

.ic-orgchart:before {
	content: "\e913";
}

.ic-pause:before {
	content: "\e914";
}

.ic-petrol:before {
	content: "\e915";
}

.ic-phone1:before {
	content: "\e637";
}

.ic-phone2:before {
	content: "\e66c";
}

.ic-phone3:before {
	content: "\e659";
}

.ic-phone4:before {
	content: "\e687";
}

.ic-play1:before {
	content: "\e916";
}

.ic-play2:before {
	content: "\e917";
}

.ic-play3:before {
	content: "\e918";
}

.ic-plus1:before {
	content: "\e674";
}

.ic-plus2:before {
	content: "\e675";
}

.ic-plus3:before {
	content: "\e672";
}

.ic-plus4:before {
	content: "\e673";
}

.ic-print:before {
	content: "\e676";
}

.ic-reload:before {
	content: "\e67a";
}

.ic-search:before {
	content: "\e680";
}

.ic-settings:before {
	content: "\e685";
}

.ic-share:before {
	content: "\e686";
}

.ic-social-facebook:before {
	content: "\e919";
}

.ic-social-rss:before {
	content: "\e91a";
}

.ic-social-twitter:before {
	content: "\e91b";
}

.ic-social-youtube:before {
	content: "\e91c";
}

.ic-star:before {
	content: "\e688";
}

.ic-thumbs:before {
	content: "\e699";
}

.ic-toggle-off:before {
	content: "\e68c";
}

.ic-toggle-on:before {
	content: "\e68d";
}

.ic-transport1:before {
	content: "\e91d";
}

.ic-transport2:before {
	content: "\e91e";
}

.ic-sphere:before {
	content: "\e91f";
}

.ic-user:before {
	content: "\e694";
}

.ic-users:before {
	content: "\e695";
}

.ic-warning:before {
	content: "\e636";
}

.ic-zoomin:before {
	content: "\e683";
}

.ic-zoomout:before {
	content: "\e682";
}

span.link-icon {
	padding-right: 5px;
	display: inline-block;
}

.main-content img.oebb-icon,
.oebb-icon {
	max-width: 100%;
	width: auto;
	height: 30px;
	margin: 5px;
	vertical-align: middle;

	@media @only-small-devices {
		height: 25px!important;
		margin: 2px;
	}

	&--align-right {
		float: right;
	}

	tbody th &--align-right {
		position: absolute;
		top: 5px;
		right: 5px;
	}
}

.oebb-icon + .indented {
	display: inline-block;
	vertical-align: top;
	margin: 5px;
}

.ic-facebook-square:before { content: "\ea91"; }
.ic-twitter:before { content: "\ea96"; }
.ic-linkedin:before { content: "\eac9"; }
.ic-xing:before { content: "\ead4"; }
.ic-googleplus:before { content: "\ea8c"; }

.social-button {
	display: inline-block;
}
.bg-bcc {
    width: 953px; height: 76px;
    background: url('../../images/logo_sprites.webp') -10px -10px;
}

.bg-eu {
    width: 280px; height: 70px;
    background: url('../../images/logo_sprites.webp') -10px -106px;
}

.bg-konzern_nav_zug {
    width: 129px; height: 91px;
    background: url('../../images/logo_sprites.webp') -10px -196px;
}

.bg-leitbetriebe_austria {
    width: 136px; height: 45px;
    background: url('../../images/logo_sprites.webp') -486px -106px;
}

.bg-rff_4c_web {
    width: 156px; height: 45px;
    background: url('../../images/logo_sprites.webp') -310px -106px;
}

.bg-xrail_web {
    width: 106px; height: 45px;
    background: url('../../images/logo_sprites.webp') -642px -106px;
}

.bg-blog {
    width: 38px; height: 38px;
    background: url('../../images/logo_sprites.webp') -870px -106px;
}

.bg-facebook {
    width: 38px; height: 38px;
    background: url('../../images/logo_sprites.webp') -159px -196px;
}

.bg-instagram {
    width: 38px; height: 38px;
    background: url('../../images/logo_sprites.webp') -217px -196px;
}

.bg-rss {
    width: 38px; height: 38px;
    background: url('../../images/logo_sprites.webp') -275px -196px;
}

.bg-twitter {
    width: 38px; height: 38px;
    background: url('../../images/logo_sprites.webp') -333px -196px;
}

.bg-youtube {
    width: 38px; height: 38px;
    background: url('../../images/logo_sprites.webp') -391px -196px;
}

.bg-waca_badge_gold {
    width: 82px; height: 45px;
    background: url('../../images/logo_sprites.webp') -768px -106px;
}

.bg-waca_badge_silver {
    width: 82px; height: 45px;
    background: url('../../images/logo_sprites.webp') -768px -196px;
}

.bg-feed {
    width: 38px; height: 38px;
    background: url('../../images/logo_sprites.webp') -275px -196px;
}

.bg-podcast {
    width: 38px; height: 38px;
    background: url('../../images/icon-podcast.png') 0 0;
	background-size: 100%;
}
